<template>
  <tbm-list :params="params"></tbm-list>
</template>
<script>
import tbmList from '@/views/business/components/tbmList';

export default {
  components: { tbmList },
  data () {
    return {
      params: {}
    }
  },
  methods: {
  },
  created () {
    let userId = JSON.parse(localStorage.getItem('userInfo')).id;
    let where = [];
    if (userId) {
      where.push({
        'field': 'pkUser',
        'opt': '=',
        'value': userId,
        'assemble': 'and'
      });
    }
    this.params = {
      url: '/hrtbm/applyHoliday/list',
      entity: {
        pkHolidayChange: 0
      },
      where,
      orderBy: 'lastmodifiedTime desc',
      viewRouteName: 'tbm/applyHoliday/applyHolidayApplyView',
      editRouteName: 'tbm/applyHoliday/applyHolidayEdit',
      changeRouteName: 'tbm/applyHoliday/applyHolidayChange',
      deleteUrl: '/hrtbm/applyHoliday/deleteHoliday',
      text: '请休假申请'
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "approvalPanel") {
      next();
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },
}
</script>
